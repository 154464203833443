<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    authority="Congress_Management_Congress_Topic"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './topicsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      entity: {
        congressTelephones: [],
      },
      entityLoaded: false,
      // congressOptions: [],
      topicOptions: [],
    }
  },
  computed: {
    congressID() {
      return this.$route.params.id
    },
    config() {
      return {
        store: storeModule,
        module: 'congress-topics',
        endpoint: 'congress-topics',
        route: 'congress-topics',
        title: {
          single: this.$t('Congress Topic'),
          plural: this.$t('Congress Topics'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formFields() {
      return [
        {
          id: 'congress',
          type: 'hidden',
          initVal: { id: this.congressID },
          hidden: true,
        },
        {
          id: 'topic',
          type: 'select',
          object: true,
          label: this.$i18n.t('Topic'),
          required: true,
          options: this.topicOptions,
        },
        {
          id: 'ordering',
          type: 'text',
          label: this.$t('ordering'),
          required: true,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchTopics')
      .then(response => {
        this.topicOptions = response.data
      })
  },
}
</script>
